var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"cbb8863c096857cc2a6afa939d26dee54438471e"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// // This file configures the initialization of Sentry on the server.
// // The config you add here will be used whenever the server handles a request.
// // https://docs.sentry.io/platforms/javascript/guides/nextjs/

// import * as Sentry from "@sentry/nextjs"
// import { env } from "~/env"

// import { SENTRY_DSN } from "~/lib/sentry/constants"
// import { isVercelProduction } from "~/lib/vercel/constants"

// Sentry.init({
//   dsn: SENTRY_DSN,
//   integrations: [new Sentry.Replay()],
//   tracesSampleRate: 0.25,
//   environment: env.NEXT_PUBLIC_VERCEL_ENV,
//   enabled: isVercelProduction,
// })
